import React from 'react';

const Logo = ({ color, ...props }) => (
    <svg
        viewBox="0 0 120 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M7.3,32.3v-6.7v-4.4v-0.2H0.7L0,24.4l0.2-0.3C0.6,23.4,1,23,1.5,22.5c1-0.9,1.9-1.2,3-1.2h0.6v11c0,0.9-0.3,1.4-1,1.6c-0.4,0.2-0.8,0.3-1,0.3h6c-0.5,0-1-0.1-1.3-0.4C7.5,33.4,7.3,33,7.3,32.3z" fill={color} />
        <path d="M17.4,33.9c-0.4,0.2-0.7,0.2-1,0.2h5.2c-0.4,0-0.8-0.1-1-0.4c-0.2-0.2-0.3-0.5-0.3-0.9v-2.8v-9.3c-0.4,0-0.9,0-1.3,0c-1,0-1.8,0.1-2.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.3,0.3,0.4,0.7,0.4,1.1v10C18.3,33.2,18,33.7,17.4,33.9zM28.8,34c-0.3-0.1-0.6-0.3-1-0.7c-0.4-0.4-0.7-0.8-1-1.1l-1.4-2.6c-0.7-1.1-1.1-1.8-1.6-1.9c0.7-0.1,1.1-0.4,1.7-0.9c0.8-0.7,1.1-1.5,1.1-2.6c0-0.9-0.2-1.6-0.7-2.1c-0.4-0.5-1-0.9-1.6-1c-0.7-0.2-1.4-0.3-2.6-0.3v0.2c0.7,0.1,1.2,0.3,1.6,0.6c0.8,0.5,1,1.3,1,2.6c0,0.9-0.3,1.5-0.7,2.1c-0.5,0.6-1.1,1-2,1v0.8c0.4,0.3,0.7,0.7,1,1.1l1.6,2.9c0.5,0.9,1,1.5,1.2,2L28.8,34L28.8,34z" fill={color} />
        <path d="M36.3,27.4v4.8c0,0.2,0,0.4,0,0.5v1.4h-3.9c0.3,0,0.6-0.1,1-0.3c0.6-0.2,0.9-0.8,0.9-1.4v-9.6c0-0.8-0.3-1.3-0.9-1.5c-0.4-0.2-0.8-0.2-1-0.2h4L36.3,27.4z" fill={color} />
        <path d="M40.1,25.2v4.5c0-0.2-0.1-0.5-0.3-0.8c-0.3-0.7-0.9-1-1.6-1.2v-0.5c0.4-0.1,0.8-0.3,1-0.5c0.5-0.4,0.8-0.9,0.9-1.2L40.1,25.2z" fill={color} />
        <path d="M43.4,24.4c-0.1-0.2-0.4-0.6-0.9-1c-0.4-0.5-0.9-0.9-1.3-1.1c-0.6-0.4-1-0.7-1.6-0.8c-0.4-0.1-0.9-0.2-1.5-0.2v-0.3h4.6L43.4,24.4z" fill={color} />
        <path d="M44.2,30.5l-0.9,3.6h-5.2v-0.2h0.7c1,0,2.1-0.4,3.2-1.2c0.5-0.4,0.9-0.7,1.2-1c0.4-0.4,0.7-0.7,0.9-0.9L44.2,30.5z" fill={color} />
        <path d="M9,21v0.4c0.7,0.2,1.2,0.5,1.8,1c0.6,0.5,1,1.1,1.4,2L11.7,21H9z" fill={color} />
        <path d="M51.1,37.7c-1.4-1.5-2.2-3.2-2.2-5v8c0.1-1.3,0.7-1.9,1.7-1.9c0.7,0,1.6,0.3,2.7,0.9c0.7,0.3,1.4,0.6,2,0.9C53.7,40,52.4,39,51.1,37.7z" fill={color} />
        <path d="M60.4,24.3c-0.3-0.5-0.6-0.7-0.6-0.8c2-1.8,4-3.8,5.4-5.2c1.9-1.9,6.7-6.9,9.4-6.9v-0.2h-8.9v0.2c3,0,2.5,3.3-0.5,6.3c-1.7,1.7-4.1,3.9-6.3,6.1c0.3,0.1,0.8,0.3,1.1,0.3C60.1,24.2,60.3,24.2,60.4,24.3z" fill={color} />
        <path d="M104.7,34c-0.3-0.1-0.6-0.3-1-0.7c-0.4-0.4-0.7-0.8-1-1.1l-1.4-2.6c-0.7-1.1-1.1-1.8-1.6-1.9c0.7-0.1,1.1-0.4,1.7-0.9c0.8-0.7,1.1-1.5,1.1-2.6c0-0.9-0.2-1.6-0.7-2.1c-0.4-0.5-1-0.9-1.6-1c-0.7-0.2-1.4-0.3-2.6-0.3v0.2c0.7,0.1,1.2,0.3,1.6,0.6c0.7,0.5,1,1.3,1,2.6c0,0.9-0.3,1.5-0.7,2.1c-0.5,0.6-1.1,1-2,1V28c0.4,0.3,0.7,0.6,1,1.1l1.6,2.9c0.5,0.9,1,1.5,1.2,2H104.7L104.7,34z M93.3,33.9c-0.4,0.2-0.7,0.2-1,0.2h5.2c-0.4,0-0.8-0.1-1-0.4c-0.2-0.2-0.3-0.5-0.3-0.9v-1V20.9c-0.4,0-0.9,0-1.3,0c-1,0-1.8,0.1-2.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0.1,0.5,0.2,0.8,0.5c0.3,0.3,0.4,0.7,0.4,1.1v9.8C94,33.2,93.7,33.7,93.3,33.9z" fill={color} />
        <path d="M77.9,33.4c-0.3-0.5-0.2-1,0-1.5l1.1-3h2.5l-0.1-0.2h-2.4l1.2-3.1l-0.1-0.3l-2.7,6.7c-0.3,0.7-0.6,1.1-1,1.6c-0.4,0.4-0.9,0.6-1.2,0.7h3.9C78.7,34.1,78.2,33.9,77.9,33.4z M84.5,33.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1h5.7c-0.5,0-0.9-0.2-1.3-0.6c-0.3-0.3-0.6-0.7-0.8-1.1l-4.8-12l-0.1-0.2l-1,2.7l2.2,5.9l1,2.8l0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7s0,0.4-0.1,0.5C84.7,33.5,84.6,33.6,84.5,33.7z" fill={color} />
        <path d="M112.2,21.1v13.1h-3.9c0.3,0,0.6-0.1,1-0.3c0.6-0.2,0.9-0.8,0.9-1.4v-9.7c0-0.8-0.3-1.3-0.9-1.5c-0.4-0.2-0.8-0.2-1-0.2H112.2z" fill={color} />
        <path d="M115.8,25.2v4.5c0-0.2-0.1-0.5-0.3-0.8c-0.4-0.7-0.9-1-1.6-1.2v-0.5c0.4-0.1,0.8-0.3,1-0.5c0.5-0.4,0.8-0.9,0.9-1.2V25.2z" fill={color} />
        <path d="M119.1,24.4c-0.1-0.2-0.4-0.6-0.9-1c-0.4-0.5-0.9-0.9-1.3-1.1c-0.6-0.4-1.1-0.7-1.6-0.8c-0.4-0.1-0.9-0.2-1.5-0.2v-0.3h4.7L119.1,24.4z" fill={color} />
        <path d="M120,30.5l-0.8,3.6h-5.3v-0.2h0.7c1,0,2.1-0.4,3.2-1.2c0.5-0.4,0.9-0.7,1.2-1c0.4-0.4,0.7-0.7,0.8-0.9L120,30.5z" fill={color} />
        <path d="M67.6,29.3c-0.9-1.1-1.9-1.9-3-2.5c-1.4-0.7-3-1.3-4.9-1.8c-2.1-0.7-3.5-1.3-4.5-1.7c-1.1-0.5-2.1-1.2-2.8-2.2c-0.7-0.9-1.1-2.2-1.1-3.7c0-1.7,0.6-3.1,1.8-4.2c1.1-1.1,2.6-1.8,4.3-2.1c-2.5,0.2-4.6,0.9-6.3,2.2c-1.7,1.3-2.5,3.2-2.5,5.5c0,1.9,0.5,3.4,1.3,4.5c0.9,1.1,1.9,2,3.1,2.5c1.1,0.6,2.8,1.1,5,1.8c2.1,0.6,3.5,1.1,4.3,1.5c1,0.5,1.9,1.1,2.7,2c0.7,0.8,1,1.9,1,3.4c0,1.8-0.6,3.1-1.7,4.2c-1.1,1.1-2.6,1.8-4.3,2c2.5-0.2,4.6-0.8,6.3-2c1.7-1.1,2.5-2.9,2.5-5.4C68.8,31.8,68.4,30.3,67.6,29.3z" fill={color} />
        <path d="M69.5,35.5c-0.3,1.5-1,2.7-2.1,3.6l1,1.3H77v-0.2C74.5,40.2,71.4,37.6,69.5,35.5z" fill={color} />
    </svg>

);

export default Logo;
