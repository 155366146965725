const Visa = (props) => (
    <svg
        {...props}
        enableBackground="new 0 0 32 20"
        height="20"
        overflow="visible"
        viewBox="0 0 32 20"
        width="32"
    >
        <g>
            <g id="Layer_2">
                <g id="VISA_x5F_flat">
                    <rect fill="#F4EFE4" height="20" id="BG" width="32" />
                    <g>
                        <path
                            clipRule="evenodd"
                            d="M11.94,7.005l-1.518,4.094l-0.162-0.832         c-0.378-1.02-1.197-2.089-2.149-2.521L9.5,13h1.642l2.441-5.995H11.94z"
                            fill="#2B5FB5"
                            fillRule="evenodd"
                        />
                        <rect
                            clipRule="evenodd"
                            fill="#2B5FB5"
                            fillRule="evenodd"
                            height="6.083"
                            width="1.55"
                            x="13.727"
                            y="6.962"
                        />
                        <path
                            clipRule="evenodd"
                            d="M20.33,7.165         c-0.307-0.121-0.791-0.253-1.39-0.253c-1.535,0-2.612,0.815-2.621,1.981c-0.01,0.864,0.771,1.346,1.357,1.632         c0.605,0.295,0.809,0.483,0.805,0.747c-0.005,0.401-0.483,0.584-0.93,0.584c-0.618,0-0.949-0.09-1.458-0.313l-0.2-0.097         l-0.217,1.346c0.362,0.166,1.03,0.313,1.727,0.317c1.629,0,2.689-0.804,2.701-2.05c0.007-0.686-0.407-1.203-1.303-1.633         c-0.542-0.276-0.875-0.461-0.869-0.743c0-0.25,0.279-0.517,0.887-0.517c0.508-0.01,0.873,0.107,1.161,0.229l0.138,0.071         L20.33,7.165z"
                            fill="#2B5FB5"
                            fillRule="evenodd"
                        />
                        <path
                            clipRule="evenodd"
                            d="M24.245,7h-1.199         c-0.371,0-0.649,0.104-0.813,0.497L19.932,13h1.628c0,0,0.267-0.74,0.327-0.902c0.176,0,1.759,0.005,1.984,0.005         C23.92,12.312,24.061,13,24.061,13H25.5L24.245,7z M22.333,10.87l0.823-2.25l0.463,2.25H22.333z"
                            fill="#2B5FB5"
                            fillRule="evenodd"
                        />
                        <path
                            clipRule="evenodd"
                            d="M9.717,7.509         c-0.094-0.38-0.365-0.492-0.703-0.505H6.5v0.102c1.944,0.497,3.227,1.719,3.761,3.163L9.717,7.509z"
                            fill="#F39C12"
                            fillRule="evenodd"
                        />
                    </g>
                    <rect fill="#2B5FB5" height="5" id="Blue" width="32" y="0" />
                    <rect fill="#F39C12" height="5" id="Orange" width="32" y="15" />
                </g>
            </g>
        </g>
    </svg>
);

export default Visa;
